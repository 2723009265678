import { render, staticRenderFns } from "./LookStudentDetail.vue?vue&type=template&id=572b7728&scoped=true&"
import script from "./LookStudentDetail.vue?vue&type=script&lang=js&"
export * from "./LookStudentDetail.vue?vue&type=script&lang=js&"
import style0 from "./LookStudentDetail.vue?vue&type=style&index=0&id=572b7728&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572b7728",
  null
  
)

export default component.exports